import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import styled from 'styled-components'

import Header from 'components/organisms/Header/Header'
import Sidenav from 'components/organisms/Sidenav'
import Footer from 'components/organisms/Footer'

import media from 'styles/media'
import Cookies from 'components/organisms/Cookies'
import useBreakpoint from 'hooks/useBreakpoint'

const Inner = styled.main`
  padding: 105px 0 0 0;

  ${media.lg.max} {
    padding: 80px 0 0 0;
  }
`

type Props = {
  children: React.ReactNode
}

const LayoutBasic: React.FC<Props> = ({ children }) => {
  const [sidenavVisible, setSidenavVisible] = useState(false)

  const toggleSidenav = () => {
    setSidenavVisible(!sidenavVisible)
  }

  const { lg } = useBreakpoint()

  return (
    <>
      <Helmet>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-T51CLP03RG"
        />
        <script>{`window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', 'G-T51CLP03RG');`}</script>
      </Helmet>
      <Header toggleSidenav={toggleSidenav} />
      {!lg && <Sidenav visible={sidenavVisible} />}
      <Inner>{children}</Inner>
      <Footer />
      <Cookies />
    </>
  )
}

export default LayoutBasic
