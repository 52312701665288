import * as React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'

import LayoutBasic from 'layouts/LayoutBasic'
import Container from 'components/atoms/Container'
import { ButtonPrimary } from 'components/atoms/Button'

import media from 'styles/media'
import Seo from 'components/atoms/Seo'

import imageBg1 from 'assets/images/404-bg-main.png'
import imageBg2 from 'assets/images/404-bg-secondary.png'

const NotFoundWrapper = styled.section`
  position: relative;
  padding: 256px 0;

  &::before,
  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
  }

  &:before {
    background-image: url(${imageBg1});
    background-size: contain;
    background-position: -50px top;

    ${media.xxl2.max} {
      background-position: -150px top;
    }

    ${media.lg.max} {
      background-size: 400px;
      background-position: -100px top;
    }

    ${media.sm.max} {
      background-position: -150px -100px;
    }
  }

  &:after {
    background-image: url(${imageBg2});
    background-size: 440px;
    background-position: right 90%;

    ${media.lg.max} {
      background-size: 330px;
      background-position: right 95%;
    }
  }

  ${media.xl2.max} {
    padding: 144px 0;
  }
`

const NotFoundInner = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  max-width: 720px;
  z-index: 3;
`

const NotFoundTitle = styled.h1`
  font-size: 130px;
  line-height: 140px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.white};
  text-align: center;

  ${media.lg.max} {
    font-size: 80px;
    line-height: 88px;
  }
`

const NotFoundSubtitle = styled.h2`
  margin: 24px 0 0 0;
  font-size: 50px;
  line-height: 58px;
  color: ${({ theme }) => theme.colors.white};
  text-align: center;
  ${media.lg.max} {
    font-size: 32px;
    line-height: 40px;
  }
`

const NotFoundText = styled.p`
  margin: 16px 0 0 0;
  font-size: 25px;
  line-height: 33px;
  color: ${({ theme }) => theme.colors.white};
  text-align: center;
  ${media.lg.max} {
    font-size: 18px;
    line-height: 26px;
  }
`

const NotFoundBtn = styled(Link)`
  ${ButtonPrimary}
  margin: 64px 0 0 0;
  ${media.lg.max} {
    margin: 48px 0 0 0;
  }
`

const NotFoundPage: React.FC = () => {
  return (
    <LayoutBasic>
      <Seo
        title="Nie znaleziono strony | Talaria Polska"
        description="Przepraszamy, nie można znaleźć żądanej strony."
      />
      <NotFoundWrapper>
        <Container>
          <NotFoundInner>
            <NotFoundTitle>404</NotFoundTitle>
            <NotFoundSubtitle>Ta strona nie istnieje</NotFoundSubtitle>
            <NotFoundText>
              Przepraszamy, nie można znaleźć żądanej strony. Proszę wrócić do
              strony głównej
            </NotFoundText>
            <NotFoundBtn to="/">Wróć do strony głównej</NotFoundBtn>
          </NotFoundInner>
        </Container>
      </NotFoundWrapper>
    </LayoutBasic>
  )
}

export default NotFoundPage
